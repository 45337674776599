import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, Form, Input, message, Button, Spin,
} from 'antd';
import { HomeOutlined, SaveOutlined } from '@ant-design/icons';
import CONSTANT from '../../config/constant';
import { axiosInstance } from '../../config/api';
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        md: { span: 8 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        md: { span: 8 },
        sm: { span: 10 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};
const ChangePass = (props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const onFinish = async (values) => {
        setLoading(true);
        let email = JSON.parse(localStorage.getItem(CONSTANT.STORAGE_KEY_USER)).email;
        await axiosInstance.post(`${CONSTANT.API.USER.CHANGE_PASS}`, { 
            email: email, password: values.password, newpassword: values.newpassword, role: 'agency' 
        })
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    message.success('Thay đổi mật khẩu thành công !')
                }
                else {
                    message.error('Thay đổi mật khẩu thất bại! Vui lòng thử lại sau.');
                }
                setLoading(false);
            }).catch(error => {
                if (error.response.data.code === 400) {
                    message.error('Mật khẩu cũ không đúng !');
                }
                else if (error.response.data.code === 401) {
                    message.error('Phiên đã hết hạn vui lòng đăng nhập lại để thay đổi mật khẩu !');
                }
                setLoading(false);
            });
    };
    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/profile">Hồ sơ</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Thay đổi mật khẩu
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">
                {
                    loading &&
                    <div style={{ textAlign: 'center' }}>
                        <Spin />
                    </div>
                }
                <Form
                    {...formItemLayout} form={form} onFinish={onFinish}                >
                    <Form.Item name="password" label="Mật khẩu cũ"
                        rules={[{ required: true, message: "Mật khẩu cũ không được để trống" }, {
                            type: 'string', max: 100, message: "Mật khẩu cũ quá 100 ký tự"
                        },]}>
                        <Input.Password placeholder="Nhập mật khẩu cũ" />
                    </Form.Item>
                    <Form.Item name="newpassword" label="Mật khẩu mới"
                        rules={[{ required: true, message: "Mật khẩu mới không được để trống" }, {
                            type: 'string', max: 100, message: "Mật khẩu mới quá 100 ký tự"
                        },]}>
                        <Input.Password placeholder="Nhập mật khẩu mới" />
                    </Form.Item>
                    <Form.Item
                        name="confirm"
                        label="Nhập lại mật khẩu mới"
                        dependencies={['newpassword']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Vui lòng nhập lại mật khẩu mới!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newpassword') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Nhập lại mật khẩu mới không đúng!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder="Nhập lại mật khẩu mới" />
                    </Form.Item>
                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" icon={<SaveOutlined />}
                            htmlType="submit"
                        >
                            Lưu thay đổi
                        </Button>
                    </Form.Item>

                </Form>
            </Layout.Content>
        </div>
    )

}
export default ChangePass;
