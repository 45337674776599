import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Layout, Breadcrumb, Row, Col, Form, Input, Checkbox, Radio, Upload, message, Button, ConfigProvider, DatePicker, Modal, Spin,
} from 'antd';
import { HomeOutlined, LoadingOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import moment from 'moment';
import CONSTANT from '../../config/constant';
import { axiosInstance } from '../../config/api';
import * as COMMON from '../../config/common';
import locale from 'antd/es/date-picker/locale/vi_VN';
const { TextArea } = Input;
const Agency = (props) => {
    const [form] = Form.useForm();
    const [idAgency, setIdAgency] = useState();
    const [loading, setLoading] = useState(false);
    const [logo, setLogo] = useState();
    const [type, setType] = useState(true);
    const [isTour, setIsTour] = useState(false);
    const [isHotel, setIsHotel] = useState(false);
    const [bussinessCerFront, setBussinessCerFront] = useState();
    const [bussinessCerBackside, setBussinessCerBackside] = useState();
    const [bussinessIssueDate, setBussinessIssueDate] = useState();
    const [identityCardFront, setIdentityCardFront] = useState();
    const [identityCardBackside, setIdentityCardBackside] = useState();
    const [identityCardIssueDate, setIdentityCardIssueDate] = useState();
    const onTypeChange = e => {
        setType(e.target.value);
    };
    const onChangeCheckbox = type => e => {
        if (type === 'tour')
            setIsTour(e.target.checked)
        else if (type === 'hotel')
            setIsHotel(e.target.checked)
    };
    const beforeUploadAvatar = type => file => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Bạn chỉ được tải ảnh JPG/PNG!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Hình ảnh phải nhỏ hơn 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    const changeUploadAvatar = type => async info => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            let avatarUrl = '';
            let avatarName = '';
            const { response } = info.file;
            if (response && response.code === 200) {
                avatarName = response.data.filename;
            }
            if (type === "logo")
                setLogo(avatarName);
            else if (type === "fCard")
                setIdentityCardFront(avatarName);
            else if (type === "bCard")
                setIdentityCardBackside(avatarName);
            else if (type === "fBussiness")
                setBussinessCerFront(avatarName);
            else if (type === "bBussiness")
                setBussinessCerBackside(avatarName);
        }
    }
    // submit
    const onFinish = async (values) => {
        debugger;
        values.logo = logo;
        // values.type = type;
        values.bussiness_cer_front = bussinessCerFront;
        values.bussiness_cer_backside = bussinessCerBackside;
        values.bussiness_issue_date = bussinessIssueDate;
        values.identity_card_front = identityCardFront;
        values.identity_card_backside = identityCardBackside;
        values.identity_card_issue_date = identityCardIssueDate;
        await axiosInstance.patch(`${CONSTANT.API.AGENCY.AGENCY}/${idAgency}`, values)
            .then(res => {

                if (res && res.data && res.data.code === 200) {
                    message.success('Sửa thông tin thành công!')
                        .then(() => props.history.push('/profile'))
                }
                else {
                    message.error('Sửa thông tin thất bại! Vui lòng thử lại sau.');
                }
            });
    };
    const onChange = type => (date) => {
        if (type === 'identity')
            setIdentityCardIssueDate(date);
        else if (type === 'bussiness')
            setBussinessIssueDate(date);
    }
    // const changeSummary = e => {
    //     console.log('Change:', e.target.value);
    //   };
    // const changeSummary=()=>{

    // }
    // effect
    useEffect(() => {
        loadAgency();
    }, []);

    // load Agency    
    const loadAgency = async () => {
        setLoading(true);
        await axiosInstance.post(`${CONSTANT.API.AGENCY.AGENCY}`, { userId: JSON.parse(localStorage.getItem(CONSTANT.STORAGE_KEY_USER))._id })
            .then(async res => {
                if (res && res.data && res.data.code === 200) {
                    const data = res.data.data;
                    setIdAgency(data._id);
                    setType(data.type);
                    setLogo(data.logo);
                    setIsTour(data.is_tour);
                    setIsHotel(data.is_hotel);
                    setBussinessCerFront(data.bussiness_cer_front);
                    setBussinessCerBackside(data.bussiness_cer_backside);
                    setBussinessIssueDate(moment(data.bussiness_issue_date));
                    setIdentityCardFront(data.identity_card_front);
                    setIdentityCardBackside(data.identity_card_backside);
                    setIdentityCardIssueDate(moment(data.identity_card_issue_date));
                    form.setFieldsValue({
                        name: data.name,
                        logo: data.logo,
                        content: data.content,
                        slug: data.slug,
                        owner_name: data.owner_name,
                        type: data.type,
                        email: data.email,
                        phone: data.phone,
                        address: data.address,
                        bussiness_code: data.bussiness_code,
                        bussiness_tax_code: data.bussiness_tax_code,
                        bussiness_issue_by: data.bussiness_issue_by,
                        identity_card_code: data.identity_card_code,
                        identity_card_issue_by: data.identity_card_issue_by,
                        bank_name: data.bank_name,
                        bank_branch_name: data.bank_branch_name,
                        account_number: data.account_number,
                        account_full_name: data.account_full_name,
                        account_full_name_upper: data.account_full_name_upper,
                        summary: data.summary,
                    });

                }
            })
            .catch(error => message.warning('Agency không tồn tại!', 2.5))
        setLoading(false);
    }
    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/profile">Hồ sơ</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Thông tin cửa hàng
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">
                {
                    loading &&
                    <div style={{ textAlign: 'center' }}>
                        <Spin />
                    </div>
                }

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={{
                        _id: "",
                        name: "",
                        logo: "",
                        content: "",
                        slug: "",
                        owner_name: "",
                        type: "",
                        email: "",
                        phone: "",
                        address: "",
                        bussiness_code: "",
                        bussiness_tax_code: "",
                        bussiness_issue_date: "",
                        bussiness_cer_front: "",
                        bussiness_cer_backside: "",
                        bussiness_issue_by: "",
                        identity_card_code: "",
                        identity_card_issue_date: "",
                        identity_card_front: "",
                        identity_card_backside: "",
                        identity_card_issue_by: "",
                        bank_name: "",
                        bank_branch_name: "",
                        account_number: "",
                        account_full_name: "",
                        account_full_name_upper: "",
                        summary:""
                    }}>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                label="Tên đại lý"
                                name="name"
                                required tooltip="Trường bắt buộc nhập"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập tên đại lý!' }, {
                                        type: 'string', max: 50, message: "Tên đại lý không quá 50 ký tự"
                                    }
                                ]}
                            >
                                <Input placeholder="Nhập tên đại lý" />
                            </Form.Item>
                            <Form.Item label="Họ tên người đại diện" name="owner_name"
                                required tooltip="Trường bắt buộc nhập"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập họ tên người đại diện!' }, {
                                        type: 'string', max: 100, message: "Họ tên người đại diện không quá 100 ký tự"
                                    }
                                ]}
                            >
                                <Input placeholder="Nhập họ tên người đại diện" />
                            </Form.Item>
                            <Form.Item label="Email" name="email"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Email không đúng định dạng',
                                    }, {
                                        type: 'string', max: 100, message: "Email không hợp lệ, tối đa 100 ký tự !"
                                    }
                                ]}>
                                <Input placeholder="Nhập email" />
                            </Form.Item>
                            <Form.Item label="Điện thoại" name="phone"
                                rules={[
                                    {
                                        type: 'string', max: 12, message: "Số điện thoại không đúng !"
                                    }
                                ]}>
                                <Input placeholder="Nhập số điện thoại" />
                            </Form.Item>
                            <Form.Item label="Địa chỉ" name="address"
                                rules={[
                                    {
                                        type: 'string', max: 200, message: "Địa chỉ tối đa 200 ký tự !"
                                    }
                                ]}>
                                <Input placeholder="Nhập địa chỉ" />
                            </Form.Item>
                            {/* Begin Images */}
                            <Form.Item label="Logo đại lý">
                                <Upload
                                    name="file"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action={`${CONSTANT.API.EXTRA.UPLOAD_AVATAR}`}
                                    data={{ type: 'agency' }}
                                    beforeUpload={beforeUploadAvatar("logo")}
                                    onChange={changeUploadAvatar("logo")}
                                    headers={{
                                        'X-TK': CONSTANT.API.KEY
                                    }}

                                >
                                    {logo ? <img src={CONSTANT.STATIC_URL + logo} alt="avatar" style={{ width: '100%' }} /> :
                                        <div>
                                            {logo ? <LoadingOutlined /> : <PlusOutlined />}
                                            <div style={{ marginTop: 8 }}>Chọn logo</div>
                                        </div>}
                                </Upload>
                            </Form.Item>
                            {/* End Images */}
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item label="Loại cửa hàng">
                                <Radio.Group value={type} onChange={onTypeChange}>
                                    <Radio value={true}>Tổ chức</Radio>
                                    <Radio value={false}>Cá nhân</Radio>
                                </Radio.Group>
                            </Form.Item>
                            {
                                type ? <>
                                    <Form.Item label="Mã số đăng ký kinh doanh" name="bussiness_code"
                                        rules={[
                                            {
                                                type: 'string', max: 30, message: "Mã số đăng ký kinh doanh tối đa 30 ký tự !"
                                            }
                                        ]}>
                                        <Input placeholder="Nhập Mã số đăng ký kinh doanh" />
                                    </Form.Item>
                                    <Form.Item label="Mã số thuế" name="bussiness_tax_code"
                                        rules={[
                                            {
                                                type: 'string', max: 30, message: "Mã số thuế tối đa 30 ký tự !"
                                            }
                                        ]}>
                                        <Input placeholder="bussiness_tax_code" />
                                    </Form.Item>
                                    <Form.Item label="Ngày cấp">
                                        <ConfigProvider locale={locale}>
                                            <DatePicker
                                                className="ant-picker-input"
                                                format="DD/MM/YYYY"
                                                defaultValue={moment()}
                                                value={bussinessIssueDate}
                                                onChange={onChange('bussiness')} />
                                        </ConfigProvider>
                                    </Form.Item>
                                    <Form.Item label="Nơi cấp giấy phép đăng ký kinh doanh" name="bussiness_issue_by"
                                        rules={[
                                            {
                                                type: 'string', max: 150, message: "Nơi cấp giấy phép đăng ký kinh doanh tối đa 150 ký tự !"
                                            }
                                        ]}>
                                        <Input placeholder="Nhập nơi cấp giấy phép đăng ký kinh doanh" />
                                    </Form.Item>
                                    <Form.Item label="Ảnh giấy phép đăng ký kinh doanh">
                                        <Row>
                                            <Col>
                                                <Upload
                                                    name="file"
                                                    listType="picture-card"
                                                    className="avatar-uploader"
                                                    style={{ width: 'auto !important' }}
                                                    showUploadList={false}
                                                    action={`${CONSTANT.API.EXTRA.UPLOAD_AVATAR}`}
                                                    data={{ type: 'agency' }}
                                                    beforeUpload={beforeUploadAvatar("fBussiness")}
                                                    onChange={changeUploadAvatar("fBussiness")}
                                                    headers={{
                                                        'X-TK': CONSTANT.API.KEY
                                                    }}

                                                >
                                                    {bussinessCerFront ? <img src={CONSTANT.STATIC_URL + bussinessCerFront} alt="Mặt trước" style={{ width: '100%' }} /> :
                                                        <div>
                                                            {bussinessCerFront ? <LoadingOutlined /> : <PlusOutlined />}
                                                            <div style={{ marginTop: 8 }}>Mặt trước</div>
                                                        </div>}
                                                </Upload>

                                            </Col>
                                            <Col>
                                                <Upload
                                                    name="file"
                                                    listType="picture-card"
                                                    className="avatar-uploader"
                                                    showUploadList={false}
                                                    action={`${CONSTANT.API.EXTRA.UPLOAD_AVATAR}`}
                                                    data={{ type: 'agency' }}
                                                    beforeUpload={beforeUploadAvatar("bBussiness")}
                                                    onChange={changeUploadAvatar("bBussiness")}
                                                    headers={{
                                                        'X-TK': CONSTANT.API.KEY
                                                    }}

                                                >
                                                    {bussinessCerBackside ? <img src={CONSTANT.STATIC_URL + bussinessCerBackside} alt="Mặt sau" style={{ width: '100%' }} /> :
                                                        <div>
                                                            {bussinessCerBackside ? <LoadingOutlined /> : <PlusOutlined />}
                                                            <div style={{ marginTop: 8 }}>Mặt sau</div>
                                                        </div>}
                                                </Upload>

                                            </Col>
                                        </Row>
                                    </Form.Item></> : <>
                                    <Form.Item label="Chứng minh nhân dân/cccd/passport" name="identity_card_code"
                                        rules={[
                                            {
                                                type: 'string', max: 50, message: "Cmnd/cccd/passport tối đa 150 ký tự !"
                                            }
                                        ]}>
                                        <Input placeholder="Nhập mã cmnd/cccd/passport" />
                                    </Form.Item>
                                    <Form.Item label="Ngày cấp">
                                        <ConfigProvider locale={locale}>
                                            <DatePicker
                                                className="ant-picker-input"
                                                format="DD/MM/YYYY"
                                                defaultValue={moment()}
                                                onChange={onChange('identity')}
                                                value={identityCardIssueDate} />
                                        </ConfigProvider>
                                    </Form.Item>
                                    <Form.Item label="Nơi cấp" name="identity_card_issue_by"
                                        rules={[
                                            {
                                                type: 'string', max: 150, message: "Nơi cấp cmnd/cccd/passport tối đa 150 ký tự !"
                                            }
                                        ]}>
                                        <Input placeholder="Nhập nơi cấp cmnd/cccd/passport" />
                                    </Form.Item>
                                    <Form.Item label="Ảnh chứng minh nhân dân/cccd/passport" layout="inline">
                                        <Row>
                                            <Col>
                                                <Upload
                                                    name="file"
                                                    listType="picture-card"
                                                    style={{ width: 'auto !important' }}
                                                    showUploadList={false}
                                                    action={`${CONSTANT.API.EXTRA.UPLOAD_AVATAR}`}
                                                    data={{ type: 'agency' }}
                                                    beforeUpload={beforeUploadAvatar("fCard")}
                                                    onChange={changeUploadAvatar("fCard")}
                                                    headers={{
                                                        'X-TK': CONSTANT.API.KEY
                                                    }}
                                                >
                                                    {identityCardFront ? <img src={CONSTANT.STATIC_URL + identityCardFront} alt="Mặt trước" style={{ width: '100%' }} /> :
                                                        <div>
                                                            {identityCardFront ? <LoadingOutlined /> : <PlusOutlined />}
                                                            <div style={{ marginTop: 8 }}>Mặt trước</div>
                                                        </div>}
                                                </Upload>

                                            </Col>
                                            <Col>
                                                <Upload
                                                    name="file"
                                                    listType="picture-card"
                                                    showUploadList={false}
                                                    action={`${CONSTANT.API.EXTRA.UPLOAD_AVATAR}`}
                                                    data={{ type: 'agency' }}
                                                    beforeUpload={beforeUploadAvatar("bCard")}
                                                    onChange={changeUploadAvatar("bCard")}
                                                    headers={{
                                                        'X-TK': CONSTANT.API.KEY
                                                    }}
                                                >
                                                    {identityCardBackside ? <img src={CONSTANT.STATIC_URL + identityCardBackside} alt="Mặt sau" style={{ width: '100%' }} /> :
                                                        <div>
                                                            {identityCardBackside ? <LoadingOutlined /> : <PlusOutlined />}
                                                            <div style={{ marginTop: 8 }}>Mặt sau</div>
                                                        </div>}
                                                </Upload>
                                            </Col>
                                        </Row>
                                    </Form.Item></>

                            }
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                            <Form.Item label="Mô tả ngắn về cửa hàng" name="summary"
                                rules={[
                                    {
                                        required: true, message: 'Vui lòng nhập mô tả ngắn về cửa hàng!',
                                        type: 'string', max: 500, message: "Mô tả ngắn về cửa hàng tối đa 500 ký tự !"
                                    }
                                ]}>
                                <TextArea showCount maxLength={500} />
                            </Form.Item>

                        </Col>
                        <Col xs={24} sm={24} md={24}>
                            <div className="ant-descriptions-title">Tài khoản ngân hàng</div>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item label="Tên ngân hàng" name="bank_name"
                                rules={[
                                    {
                                        required: true, message: 'Vui lòng nhập tên ngân hàng!',
                                        type: 'string', max: 100, message: "Tên ngân hàng tối đa 100 ký tự !"
                                    }
                                ]}>
                                <Input placeholder="Nhập tên ngân hàng" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item label="Tên chi nhánh ngân hàng" name="bank_branch_name"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập tên chi nhánh !' },
                                    {
                                        type: 'string', max: 100, message: "Tên chi nhánh tối đa 100 ký tự !"
                                    }
                                ]}>
                                <Input placeholder="Nhập tên chi nhánh" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item label="Số tài khoản" name="account_number"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập số tài khoản !' },
                                    {
                                        type: 'string', max: 100, message: "Số tài khoản tối đa 100 ký tự !"
                                    }
                                ]}>
                                <Input placeholder="Nhập số tài khoản" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item label="Họ và tên chủ tài khoản" name="account_full_name"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập họ và tên!' },
                                    {
                                        type: 'string', max: 64, message: "Họ và tên tối đa 64 ký tự !"
                                    }
                                ]}>
                                <Input placeholder="Nhập họ và tên" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item label="Tên chủ tài khoản (Viết in hoa không dấu)" name="account_full_name_upper"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập tên chủ tài khoản !' },
                                    {
                                        type: 'string', max: 64, message: "Họ và tên tối đa 64 ký tự !"
                                    }
                                ]}>
                                <Input placeholder="Nhập tên in hoa không dấu - NGUYEN VAN A" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <div className="footer-toolbar">
                        <div className="right">
                            <Button type="primary" icon={<SaveOutlined />}
                                htmlType="submit"
                            >
                                Lưu thông tin
                            </Button>
                        </div>

                    </div>
                </Form>

            </Layout.Content>

        </div >
    )

}

export default Agency;
