import React from 'react';
import { Layout, Result, Card, Row, Col } from 'antd';
import { SmileOutlined, CarryOutOutlined } from '@ant-design/icons';

import './dashboard.css';

export default class Dashboard extends React.PureComponent {
    render() {

        return (
            <div className="dashboard">

                <div className="banner">
                    <Result
                        icon={<SmileOutlined style={{ color: 'white' }} />}
                        title="Tuyệt vời, Bạn đang ở trang quản trị dành cho Người bán hàng trên Tatinta"
                        subTitle="Bạn có thể bắt đầu tạo Sản phẩm và bán hàng cùng Tatinta."
                        style={{ margin: '0 auto', padding: '20px 0px 15px 0px' }}
                    />
                </div>
                {/* <Layout.Content>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Card
                                style={{ marginTop: 16 }}
                                type="inner"
                                title={(
                                    <>
                                        <CarryOutOutlined style={{fontSize:24}} />
                                        <span>Tổng Booking</span>
                                    </>
                                )}
                                extra={<a href="#">More</a>}
                            >
                                Inner Card content
                            </Card>
                        </Col>
                    </Row>
                </Layout.Content> */}

            </div>
        )
    }
}