import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, Table, Space, Form, Input, Button, Divider, Popconfirm,
    message, Tag
} from 'antd';
import { HomeOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { axiosInstance } from '../../../config/api';
import CONSTANT from '../../../config/constant';
const TAGS = {
    'wait_paid': { text: 'Chờ thanh toán', color: 'orange' },
    'paid': { text: 'Đã thanh toán', color: 'green' },
    'pending': { text: 'Chờ xác nhận', color: 'red' },
    'confirmed': { text: 'Đã xác nhận', color: 'orange' },
    'fulfill': { text: 'Đóng gói và vận chuyển', color: 'orange' },
    'delivery': { text: 'Đang giao hàng', color: 'red' },
    'abandoned': { text: 'Khách đã hủy', color: 'blue' },
    'completed': { text: 'Hoàn thành', color: 'green' },
    'cancelled': { text: 'Cửa hàng hủy', color: '' },
    'refunded': { text: 'Hoàn tiền', color: 'red' },
    'failed': { text: 'Đơn hàng lỗi', color: 'red' },
    'trash': { text: 'Rác', color: 'red' },
    '-': { text: 'Trash', color: 'red' }
};
const getRandomuserParams = params => ({
    perPage: params.pagination.pageSize,
    page: params.pagination.current,
    sort: params.sort ? params.sort : `{"created_at": -1}`
});

export default class ListOrder extends React.PureComponent {
    constructor(props) {
        super(props);
        // define column table
        this.columns = [
            {
                title: '#',
                dataIndex: 'uid',
            },
            {
                title: 'Email',
                dataIndex: 'shipping',
                render: shipping => shipping.email
            },
            {
                title: 'Tổng cộng', dataIndex: 'total', align: 'right',
                render: (total, record) => record.currency + ' ' + total.toLocaleString('en-US')
            },
            {
                title: 'Trạng thái', type: 'tag', dataIndex: 'status',
                render: status => <Tag color={TAGS[status].color}>{TAGS[status].text}</Tag>
            },
            {
                title: 'Ngày tạo', dataIndex: 'created_at', sorter: true, width: '170px',
                render: created_at => `${moment(new Date(created_at)).format(CONSTANT.DATE_FORMAT)}`
            },
            {
                title: 'Ngày thanh toán', dataIndex: 'date_paymented', sorter: true, width: '170px',
                render: date_paymented => date_paymented ? `${moment(new Date(date_paymented)).format(CONSTANT.DATE_FORMAT)}` : ""
            },
            {
                title: 'Hành động', width: '300px',
                render: (text, record) => (
                    <Space size="middle">
                        <Link to={`/order-detail/${record._id}`}><EditOutlined /> Chi tiết</Link>
                        {/* <Divider type="vertical" />
                        <Popconfirm title="Bạn muốn xóa product này?" onConfirm={() => this.handleDelete(record._id)}>
                            <Link to=''><DeleteOutlined /> Xóa</Link>
                        </Popconfirm> */}
                    </Space>
                )
            },
        ];
        this.state = {
            userId: JSON.parse(localStorage.getItem(CONSTANT.STORAGE_KEY_USER))._id,
            selectedRowKeys: [],
            loading: false,
            dataOrders: [],
            pagination: {
                current: 1,
                pageSize: 10
            }
        };
    }
    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    }
    handleSearch = (values) => {
        const { pagination } = this.state;
        this.fetch({
            uid: values.keySearch,
            pagination
        });
    }
    async componentDidMount() {
        const { pagination } = this.state;
        this.fetch({ pagination });
    }

    fetch = async (params = {}) => {
        this.setState({ loading: true });
        let query = '';
        if (params.uid) {
            query = 'uid=' + params.uid + '&';
        }
        query += new URLSearchParams(getRandomuserParams(params)).toString();
        if (this.state.userId) {
            this.setState({ loading: true });
            await axiosInstance.get(`${CONSTANT.API.PRODUCT.ORDER}?${query}&agency=${this.state.userId}`)
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        const { docs, total } = res.data.data;
                        this.setState({
                            loading: false,
                            dataOrders: docs,
                            pagination: {
                                ...params.pagination,
                                total
                            }
                        })
                    }
                })
        }
        else {
            this.setState({ loading: false });
            message.warning('Không có hóa đơn nào !').then(res => this.props.history.goBack());
        }
    }

    handleTableChange = (pagination, filters, sorter) => {
        let options = { pagination };
        if (Object.keys(sorter).length > 0)
            options.sort = `{"${sorter.field}": ${sorter.order === 'ascend' ? 1 : -1}}`;
        this.fetch(options);
    }
    // handleDelete = async (key) => {
    //     if (key) {
    //         await axiosInstance.delete(`${CONSTANT.API.PRODUCT.ORDER}/${key}`)
    //             .then((res) => {
    //                 if (res && res.data && res.data.code === 200) {
    //                     const dataSource = [...this.state.dataOrders];
    //                     this.setState({
    //                         dataOrders: dataSource.filter((item) => item._id !== key),
    //                     });
    //                     message.success('Xóa thành công!');
    //                 }
    //                 else {
    //                     message.error('Xóa không thành công');
    //                 }
    //             })
    //             .catch(err => message.error('Xóa không thành công'))
    //     }
    // }
    render() {
        const { loading, selectedRowKeys, dataOrders, pagination } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <div>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item >
                        <Link to="/"><HomeOutlined /></Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Danh sách đơn hàng
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Layout.Content className="layout-content">
                    {/* Begin form search */}
                    <Form
                        name="customized_form_controls"
                        layout="inline"
                        onFinish={this.handleSearch}
                        initialValues={{
                            keySearch: ''
                        }}
                    >
                        <Form.Item name="keySearch" label="Tìm kiếm">
                            <Input placeholder="Nhập mã số đơn hàng" />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                        </Form.Item>
                    </Form>
                    {/* End form search */}
                    <Table
                        rowSelection={rowSelection}
                        columns={this.columns}
                        dataSource={dataOrders}
                        loading={loading}
                        pagination={pagination}
                        rowKey={record => record._id}
                        onChange={this.handleTableChange}
                    />
                </Layout.Content>
            </div>
        )
    }
}
