import CONFIG from './constant';
// off auto complete select
export function offAutoCompleteSelect() {
    const el = document.getElementsByClassName("ant-select-selection-search-input");
    for (let i = 0; i < el.length; i++) {
        el[i].setAttribute(
            "autocomplete",
            "registration-select"
        );
    }
}
export function calculateDate(checkin, checkout) {
    let dateCheckin = new Date(checkin);
    let dateCheckout = new Date(checkout);
    // time diff
    let timeDiff = Math.abs(dateCheckout.getTime() - dateCheckin.getTime());
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays;
}
// currency format
export function currencyFormat(num, fixed = 0) {
    return parseFloat(num).toFixed(fixed).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}
export function getImageVariant(itemOrder){
    if(itemOrder){
        let filename = '';
        if(itemOrder.variant && itemOrder.variant.images && itemOrder.variant.images.length > 0){
            filename = itemOrder.variant.images[0].filename;
        }
        else{
            if(itemOrder.product && itemOrder.product.images && itemOrder.product.images.length > 0){
                filename = itemOrder.product.images[0].filename
            }
            else{
                return `static/images/no-image.jpg`;
            }
        }
        return `${CONFIG.STATIC_URL}/${filename}`;
    } 
    else
        return `static/images/no-image.jpg`;
}
export function getCurrency(currency){
    let arrCurrency = {
        USD: '$', EUR: '€', CHF: 'Fr', CAD:'C$', KRW: '₩', JPY: '¥', VND: '₫'
    }

    return arrCurrency[`${currency}`] || '$';
}
export function getImageVariantCoupon(itemOrder){
    if(itemOrder){
        let filename = '';
        if(itemOrder.variant && itemOrder.variant.images && itemOrder.variant.images.length > 0){
            filename = itemOrder.variant.images[0].filename;
        }
        else{
            if(itemOrder.coupon.images.length > 0){
                filename = itemOrder.coupon.images[0].filename
            }
            else{
                return `static/images/no-image.jpg`;
            }
        }
        return `${CONFIG.STATIC_URL}/${filename}`;
    } 
    else
        return `static/images/no-image.jpg`;
}