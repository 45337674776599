import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Breadcrumb, Table, Button, Divider, Form, Input, Row, Col, Avatar, Space, Popconfirm, message, Tag } from 'antd';
import { HomeOutlined, PlusOutlined, EditOutlined, DeleteOutlined, WindowsOutlined } from '@ant-design/icons';
import moment from 'moment';

import { axiosInstance } from '../../config/api';
import CONSTANT from '../../config/constant';

const getRandomuserParams = params => ({
    perPage: params.pagination.pageSize,
    page: params.pagination.current,
    is_deleted: false,
    sort: params.sort ? params.sort : `{"created_at": -1}`,
    ...params.options
});

export default class ListProduct extends React.PureComponent {

    constructor(props) {
        super(props);

        // define column table
        this.columns = [
            {
                title: 'Ảnh', dataIndex: 'images',
                render: images => images && images.length > 0 && <Avatar size={48} shape="square" src={`${CONSTANT.STATIC_URL}${images[0].filename}`} />,
                width: '50px'
            },
            { title: 'Tên', dataIndex: 'name' },
            {
                title: 'Danh mục', dataIndex: 'categories',
                render: categories => categories[0].name
            },
            {
                title: 'Trạng thái', dataIndex: 'is_active', width: '150px',
                render: is_active => is_active ? <Tag color="green">Hiển thị</Tag> : <Tag color="red">Tạm ẩn</Tag>
            },
            {
                title: 'Ngày tạo', dataIndex: 'created_at', width: '170px',
                render: created_at => `${moment(new Date(created_at)).format(CONSTANT.DATE_FORMAT)}`
            },
            {
                title: 'Hành động', width: '200px',
                render: (text, record) => (
                    <Space size="middle">
                        <Link to={`/product/action/${record._id}`}><EditOutlined /> Sửa</Link>
                        <Divider type="vertical" />
                        <Popconfirm title="Bạn muốn xóa product này?" onConfirm={() => this.handleDelete(record._id)}>
                            <Link to={`/product/action/${record._id}`}><DeleteOutlined /> Xóa</Link>
                        </Popconfirm>
                    </Space>
                )
            }
        ];
        this.state = {
            selectedRowKeys: [],
            loading: false,
            data: [],
            pagination: {
                current: 1,
                pageSize: 10
            },
            keySearch: ''
        };
    }
    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    }
    handleDelete = async (key) => {
        if (key) {
            await axiosInstance.delete(`${CONSTANT.API.PRODUCT.PRODUCT}/${key}`)
                .then((res) => {
                    if (res && res.data && res.data.code === 200) {
                        const dataSource = [...this.state.data];
                        this.setState({
                            data: dataSource.filter((item) => item._id !== key),
                        });
                        message.success('Xóa thành công!');
                    }
                    else {
                        message.error('Xóa không thành công');
                    }
                })
                .catch(err => message.error('Xóa không thành công'))
        }
    }

    async componentDidMount() {
        const { pagination } = this.state;
        this.fetch({ pagination });
    }

    handleTableChange = (pagination, filters, sorter) => {
        let options = {};
        if (Object.keys(sorter).length > 0)
            options.sort = `{"${sorter.field}": ${sorter.order === 'ascend' ? 1 : -1}}`;
        if(this.state.keySearch !== ''){
            options.name = this.state.keySearch;
        }
        this.fetch({pagination,options});
    }
    handleSearch = (values) => {
        const { pagination } = this.state;
        let options = {};
        if(values.keySearch){
            options.name = values.keySearch;
            this.setState({keySearch: values.keySearch});
        }
        this.fetch({
            pagination, options
        });
    }
    fetch = async (params = {}) => {
        this.setState({ loading: true });
        let query = new URLSearchParams(getRandomuserParams(params)).toString();
        let user = localStorage.getItem(`${CONSTANT.STORAGE_KEY_USER}`);
        user = JSON.parse(user);
        await axiosInstance.get(`${CONSTANT.API.PRODUCT.PRODUCT}?${query}&created_by=${user._id}`)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    const { docs, total } = res.data.data;
                    this.setState({
                        loading: false,
                        data: docs,
                        pagination: {
                            ...params.pagination,
                            total
                        }
                    })
                }

            })
    }

    render() {
        const { loading, selectedRowKeys, data, pagination } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <div>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item >
                        <Link to="/"><HomeOutlined /></Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Danh sách sản phẩm
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Layout.Content className="layout-content">
                    {/* Begin form search */}
                    <Form
                        name="customized_form_controls"
                        layout="inline"
                        onFinish={this.handleSearch}
                        initialValues={{
                            keySearch: ''
                        }}
                    >
                        <Form.Item name="keySearch" label="Tìm kiếm">
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                        </Form.Item>
                    </Form>
                    {/* End form search */}
                    <Divider />
                    <Row justify="end" style={{ marginBottom: 20 }}>
                        <Col>
                            <Button type="primary" icon={<PlusOutlined />} onClick={() => this.props.history.push('/product/action')} >
                                Thêm mới
                            </Button>
                        </Col>
                    </Row>

                    <Table
                        //rowSelection={rowSelection}
                        columns={this.columns}
                        dataSource={data}
                        loading={loading}
                        pagination={pagination}
                        rowKey={record => record._id}
                        onChange={this.handleTableChange}
                    />
                </Layout.Content>
            </div>
        )
    }
}
