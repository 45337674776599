import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, message, Spin, Descriptions, Button, Divider
} from 'antd';
import { HomeOutlined, RightOutlined } from '@ant-design/icons';
import CONSTANT from '../../config/constant';
import { axiosInstance } from '../../config/api';
export default class Infomation extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            profile: JSON.parse(localStorage.getItem(CONSTANT.STORAGE_KEY_USER)),
            loading: false,
            agency: null,
        };
    }
    async componentDidMount() {
        this.loadAgency();
    }
    loadAgency = async () => {
        this.setState({ loading: true });
        await axiosInstance.post(`${CONSTANT.API.AGENCY.AGENCY}`, { userId: this.state.profile._id }).then(res => {
                if (res && res.data && res.data.code === 200 && res.data.data) {
                    this.setState({ loading: false, agency: res.data.data });
                }
            })
            .catch(error => { message.warning('Lỗi lấy thông tin cửa hàng!', 2.5); this.setState({ loading: false }); })
    }

    render() {
        return (
            <div>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item >
                        <Link to="/"><HomeOutlined /></Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/infomation">Hồ sơ</Link>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Layout.Content className="layout-content">
                    {
                        this.state.loading &&
                        <div style={{ textAlign: 'center' }}>
                            <Spin />
                        </div>
                    }
                    <Descriptions title="Tài khoản đăng nhập" extra={<Button type="link" onClick={() => this.props.history.push('/profile/user')} >Chi tiết <RightOutlined /></Button>}>
                        <Descriptions.Item label="Mã tài khoản">{this.state.profile._id}</Descriptions.Item>
                        <Descriptions.Item label="Tên đầy đủ">{this.state.profile.firstname + ' ' + this.state.profile.lastname}</Descriptions.Item>
                        <Descriptions.Item label="Email">{this.state.profile.email}</Descriptions.Item>
                        <Descriptions.Item label="Điện thoại">{this.state.profile.phone}</Descriptions.Item>
                    </Descriptions>
                    <Divider />
                    {
                        this.state.agency ? <Descriptions title="Thông tin cửa hàng" extra={<Button type="link" onClick={() => this.props.history.push('/profile/agency')}>Chi tiết <RightOutlined /></Button>}>
                            <Descriptions.Item label="Mã cửa hàng" >{this.state.agency && this.state.agency._id ? this.state.agency._id : ""}</Descriptions.Item>
                            <Descriptions.Item label="Tên cửa hàng">{this.state.agency.name}</Descriptions.Item>
                            <Descriptions.Item label="Email">{this.state.agency.email}</Descriptions.Item>
                            <Descriptions.Item label="Điện thoại">{this.state.agency.phone}</Descriptions.Item>
                            <Descriptions.Item label="Địa chỉ">{this.state.agency.address}</Descriptions.Item>
                        </Descriptions> : ""
                    }

                </Layout.Content>
            </div>
        )
    }
}