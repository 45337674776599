import React from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import {
    DashboardOutlined,
    HomeOutlined,
    RetweetOutlined, NodeExpandOutlined, GiftOutlined, FileDoneOutlined
} from '@ant-design/icons';

const { Sider } = Layout;

const { SubMenu } = Menu;

export default class MenuSider extends React.PureComponent {
    state = {
        current: 'tour',
        agency_type: localStorage.getItem('tatinta-agency-type')
    }
    // Menu click handle
    handleClick = e => {
        this.setState({ current: e.key });
    }
    render() {
        return (
            
            <Sider
                trigger={null} collapsible collapsed={this.props.collapsed}
            >   
                <div className="logo">
                    <Link to="/">
                        <img src="tatinta-white.png" alt="logo tatinta" />
                    </Link>
                </div>
                <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>

                    <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
                        <Link to="/">Trang chủ</Link>
					</Menu.Item>
                    <Menu.Item key="product" icon={<GiftOutlined />}>
                        <Link to="/product">Quản lý sản phẩm</Link>
					</Menu.Item>
                    <Menu.Item key="order" icon={<FileDoneOutlined />}>
                        <Link to="/order">Quản lý đơn hàng</Link>
					</Menu.Item>                 
                </Menu>
            </Sider>
        )
    }
}