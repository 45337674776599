
const CONSTANT = {};

if(process.env.NODE_ENV === 'development'){
    CONSTANT.BASE_API = `http://localhost:7200/v1/`;
    //CONSTANT.BASE_API = `https://api.tatinta.com/v1/`;
    CONSTANT.STATIC_URL = `http://localhost:7200/v1/static/`;
}
else{
    CONSTANT.BASE_API = `https://api.tatinta.com/v1/`;
    CONSTANT.STATIC_URL = `https://api.tatinta.com/v1/static/`;
}


CONSTANT.API = {
    KEY: "hbacrxhfgxhplcwrmfg2cu0946496x",
    AUTH: {
        LOGIN: `${CONSTANT.BASE_API}auth/login`,
        FORGOT_PASS: `${CONSTANT.BASE_API}auth/forgot-password`,
        GET_PASS: `${CONSTANT.BASE_API}auth/forgot-password`,
    },
    USER: {
        CHECK: `${CONSTANT.BASE_API}users/check`,
        ACTIVE: `${CONSTANT.BASE_API}users/active`,
        CHANGE_PASS: `${CONSTANT.BASE_API}auth/change-password`,
        UPDATE: `${CONSTANT.BASE_API}users/`,
    },
    AGENCY: {
        AGENCY: `${CONSTANT.BASE_API}agency/agency/get-info-agency`,
        IMAGE:  `${CONSTANT.BASE_API}agency/image`
    },
    PRODUCT: {
        PRODUCT: `${CONSTANT.BASE_API}shop/product/product`,
        VARIANT:`${CONSTANT.BASE_API}shop/product/variant`,
        ATTRIBUTE: `${CONSTANT.BASE_API}shop/product/attribute`,
        CATEGORY: `${CONSTANT.BASE_API}shop/product/category/tree`,
        IMAGE:  `${CONSTANT.BASE_API}shop/product/image`,
        ORDER:  `${CONSTANT.BASE_API}shop/product/order`,
        PAYMENT:`${CONSTANT.BASE_API}shop/product/order-checkout`,
    },    
    TRANSACTION:{
        TRANSACTION:`${CONSTANT.BASE_API}shop/product/order/payment`
    },
    MEDIA: {
        MEDIA: `${CONSTANT.BASE_API}audio/media`,
        UPLOAD_IMAGE: `${CONSTANT.BASE_API}audio/media/upload/image`,
        SAVE_FILE: `${CONSTANT.BASE_API}audio/media/upload/save-file`
    },
    EXTRA: {
        UPLOAD_AVATAR: `${CONSTANT.BASE_API}extra/upload/avatar`,
        UPLOAD_IMAGE: `${CONSTANT.BASE_API}extra/upload/image`,
        SAVE_FILE: `${CONSTANT.BASE_API}extra/upload/save-file`,
    },
    DESTINATION: {
        AREA: `${CONSTANT.BASE_API}destination/area`,
        COUNTRY: `${CONSTANT.BASE_API}destination/country`,
        CITY: `${CONSTANT.BASE_API}destination/city`
    },
    AUDIO_OBJECT: `${CONSTANT.BASE_API}audio/audio-object`,
    CONTENT: `${CONSTANT.BASE_API}audio/content`,
}

CONSTANT.STORAGE_KEY_USER = `auth-user-${process.env.NODE_ENV}`;
CONSTANT.DATE_FORMAT = 'DD/MM/YY - H:m';
CONSTANT.STATUS = {
    trash: 'Nháp',
    processing: 'Đang xử lý',
    completed:'Hoàn thành',
    cancelled: 'Hủy',
    paid: 'Đã thanh toán',
    unpaid: 'Không thanh toán',
    wait_paid: 'Chờ thanh toán',
    pending: 'Chưa giải quyết'
}
CONSTANT.STATUS_COLOR = {
    trash: 'default',
    processing: 'orange',
    completed:'green',
    cancelled: 'magenta',
    paid: 'geekblue',
    unpaid: 'volcano',
    wait_paid: 'gold',
    pending: 'purple'
}

export default CONSTANT;
