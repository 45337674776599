import React from 'react';
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, Card, Select, Button, Row, Col, Descriptions, Input,
    message, Tag, Spin
} from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import moment from 'moment';
import { axiosInstance } from '../../../config/api';
import CONSTANT from '../../../config/constant';
import * as COMMON from '../../../config/common';

export default class DetailOrder extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            arrStatus: [
                { label: "Chờ xác nhận", value: "paid", disabled: true },
                { label: "Xác nhận có hàng và chuẩn bị hàng", value: "confirmed" },
                { label: "Đang chuẩn bị hàng", value: "fulfill" },
                { label: "Đang giao hàng", value: "delivery" },
                { label: "Hoàn thành", value: "completed" },
                { label: "Hủy không giao hàng", value: "cancelled" },
            ],
            loading: false,
            order: null,
            statusOrder: '',
            transferId: null,
            transaction: null,
            userId: JSON.parse(localStorage.getItem(CONSTANT.STORAGE_KEY_USER))._id,
            loadingOrder: true
        };
    }

    onChangeSelect = async (value) => {
        this.setState({ statusOrder: value });
    }

    async componentDidMount() {
        this.fetch();
    }

    fetch = async () => {
        this.setState({ loadingOrder: true });
        if (this.props.match.params.id) {
            await axiosInstance.get(`${CONSTANT.API.PRODUCT.ORDER}/${this.props.match.params.id}?agency=${this.state.userId}`)
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        this.setState({
                            loadingOrder: false,
                            order: res.data.data,
                            statusOrder: res.data.data.status,
                        })
                    }
                }).catch(error => {
                    this.setState({ loadingOrder: false });
                    message.error('Lỗi lấy thông tin đơn hàng: ' + error.message);
                })
        }
        else {
            this.setState({ loadingOrder: false });
            message.warning('Đơn hàng không tồn tại mã đơn hàng !').then(res => this.props.history.goBack());
        }
    }
    updateStatus = async () => {
        this.setState({ loading: true });
        if (this.props.match.params.id) {
            await axiosInstance.patch(`${CONSTANT.API.PRODUCT.ORDER}/${this.props.match.params.id}`, { status: this.state.statusOrder, actUpd: 'udpStatus' })
                .then(res => {
                    this.setState({ loading: false });
                    if (res && res.data && res.data.code === 200) {
                        message.success('Cập nhật trạng thái thành công!')
                    }
                    else {
                        message.error('Cập nhật trạng thái không thành công!');
                    }
                })
                .catch(error => {
                    message.error('Cập nhật trạng thái không thành công!' + error.message);
                    this.setState({ loading: false });
                });
        }
        else {
            this.setState({ loading: false });
            message.error('Cập nhật trạng thái không thành công!');
        }
    }

    render() {
        const { loading, order, loadingOrder } = this.state;
        return (
            <div>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item >
                        <Link to="/"><HomeOutlined /></Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >
                        <Link to="/order">Danh sách đơn hàng</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Thông tin đơn hàng
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Layout.Content className="layout-content">
                    {
                        loadingOrder &&
                        <div style={{ textAlign: 'center' }}>
                            <Spin />
                        </div>
                    }
                    {
                        !loadingOrder && order ? <>
                            <Row gutter={16}>
                                <Col xs={24} sm={24} md={18}>
                                    <Descriptions title={"Order: #TATINTA-S" + order.uid}></Descriptions>
                                    <Descriptions title="Thông tin giao hàng" column={{ md: 2 }}>
                                        <Descriptions.Item label="Họ tên" labelStyle={{ color: '#777' }}>{order.shipping.first_name + ' ' + order.shipping.last_name}</Descriptions.Item>
                                        <Descriptions.Item label="Email" labelStyle={{ color: '#777' }}>{order.shipping.email}</Descriptions.Item>
                                        <Descriptions.Item label="Điện thoại" labelStyle={{ color: '#777' }}>{order.shipping.phone}</Descriptions.Item>
                                        <Descriptions.Item label="Địa chỉ" labelStyle={{ color: '#777' }}>{order.shipping.address_1 + ',' + order.shipping.address_2 + ',' + order.shipping.city + ',' + order.shipping.country}</Descriptions.Item>
                                        {
                                            order.status === 'completed' &&
                                            <Descriptions.Item label="Trạng thái" labelStyle={{ color: '#777' }}><Tag color="green">Đơn hàng đã giao hoàn tất</Tag></Descriptions.Item>
                                        }
                                    </Descriptions>
                                    {/* <Descriptions title="Giao dịch thanh toán" column={{ md: 2 }}>
                                        <Descriptions.Item label="Phương thức thanh toán" labelStyle={{ color: '#777' }}>{order.payment_method_title || '-'}</Descriptions.Item>
                                        <Descriptions.Item label="Tình trạng giao dịch" labelStyle={{ color: '#777' }}>{CONSTANT.STATUS[`${this.state.statusOrder}`]}</Descriptions.Item>
                                        <Descriptions.Item label="Ngày tạo giao dịch" labelStyle={{ color: '#777' }}>{order.created_at ? moment(order.created_at).format(CONSTANT.DATE_FORMAT) : "-"}</Descriptions.Item>
                                        <Descriptions.Item label="Ngày hoàn thành giao dịch" labelStyle={{ color: '#777' }}>{order.date_paymented ? moment(order.date_paymented).format(CONSTANT.DATE_FORMAT) : "-"}</Descriptions.Item>
                                        <Descriptions.Item label="Mã giao dịch " labelStyle={{ color: '#777' }}>{order.payment_id}</Descriptions.Item>
                                    </Descriptions> */}

                                    <div className="ant-table-content">
                                        <table style={{ tableLayout: "auto", width: "100%" }}>
                                            <thead className="ant-table-thead">
                                                <tr>
                                                    <th className="ant-table-cell">Hình ảnh</th>
                                                    <th className="ant-table-cell">Tên sản phẩm</th>
                                                    <th className="ant-table-cell">Số lượng</th>
                                                    <th className="ant-table-cell">Đơn giá</th>
                                                    <th className="ant-table-cell">Thành tiền</th>
                                                </tr>
                                            </thead>
                                            <tbody className="ant-table-tbody">
                                                {
                                                    order.shop[0].items && order.shop[0].items.length > 0 && order.shop[0].items.map((item, index) => (
                                                        <tr className="ant-table-row" key={index}>
                                                            <td className="ant-table-cell" style={{ width: '150px' }}>
                                                                <img src={COMMON.getImageVariant(item)} style={{ width: '100%' }}
                                                                    alt={item.product.name}
                                                                />
                                                            </td>
                                                            <td className="ant-table-cell" style={{ width: '400px' }}>
                                                                <h4 className="product-title">{item.product.name}</h4>
                                                                <p className="product-attribute">
                                                                    {
                                                                        item.variant && item.variant.attributes.map((attr, indexAttr) => {
                                                                            let str = attr.options[0].name;
                                                                            if (indexAttr < item.variant.attributes.length - 1)
                                                                                str += " / ";
                                                                            return str + ' ';
                                                                        })
                                                                    }
                                                                </p>
                                                            </td>
                                                            <td className="ant-table-cell" style={{ textAlign: 'right' }}>
                                                                {item.quantity}
                                                            </td>
                                                            <td className="ant-table-cell" style={{ textAlign: 'right' }}>
                                                                {COMMON.currencyFormat(parseFloat(item.price))} {COMMON.getCurrency(item.product.currency)}
                                                            </td>
                                                            <td className="ant-table-cell" style={{ textAlign: 'right' }}>
                                                                {COMMON.currencyFormat(parseFloat(item.price * item.quantity))} {COMMON.getCurrency(item.product.currency)}
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                        <div className="ant-table-footer">
                                            <div style={{ float: 'left', width: '100%', padding: '10px 0', borderBottom: '1px #ccc solid' }}>
                                                <span style={{ float: 'left', fontWeight: 700 }}>Tổng tiền hàng</span>
                                                <span style={{ float: 'right', fontWeight: 700 }}>
                                                    {order.shop[0].shop_total && `${COMMON.currencyFormat(order.shop[0].shop_total)} ${COMMON.getCurrency(order.currency)}` || 0}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={6}>
                                    {
                                        order.status !== 'completed' && <Card title="Cập nhật trạng thái đơn hàng" bordered={false}>
                                            <Select
                                                placeholder="Chọn trạng thái"
                                                onChange={this.onChangeSelect}
                                                value={this.state.statusOrder}
                                                style={{ width: '100%' }}>
                                                {
                                                    this.state.arrStatus.map(item => <Select.Option key={item.value} disabled={item?.disabled}>{item.label}</Select.Option>)
                                                }
                                            </Select>
                                            <Row justify="end" style={{ margin: '20px 0' }}>
                                                <Col>
                                                    <Button type="primary" loading={loading} onClick={() => this.updateStatus()} disabled={order.status == this.state.statusOrder || loading}>
                                                        Cập nhật
                                                    </Button>
                                                </Col>
                                            </Row>

                                        </Card>

                                    }

                                </Col>
                            </Row></> : null
                    }

                </Layout.Content>
            </div>
        )
    }
}
