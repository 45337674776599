import React from 'react';
import { Switch, Route , BrowserRouter as Router, Redirect } from 'react-router-dom';
import { isLoggedIn } from 'axios-jwt';

import {
    Dashboard,
    // Product
    ListProduct, ActionProduct,ListOrder,DetailOrder,   
    //profile
    Infomation,Agency, User, ChangePass  
} from '../modules';

// import layout
import Login from '../layouts/login';
import MainLayout from '../layouts/main';


const PrivateRoute = ({component: Component, layout: Layout = MainLayout, authed, ...rest}) => {
    return (
        <Route 
            {...rest}
            render={(props) => authed === true ? (
                    <Layout>
                        <Component {...props}/> 
                    </Layout>
                ): <Redirect to={{ pathname: '/login', state: {from: props.location} }} />
            }
        />
    )
}

export default function AppRouter() {
    return (
        <Router>
            <Switch>
                <Route path="/login" component={Login} />
                <PrivateRoute authed={isLoggedIn()} path="/" exact component={Dashboard}/>
                <PrivateRoute authed={isLoggedIn()} path="/product" exact component={ListProduct}/>
                <PrivateRoute authed={isLoggedIn()} path="/product/action" exact component={ActionProduct}/>
                <PrivateRoute authed={isLoggedIn()} path="/product/action/:id" component={ActionProduct}/>
                <PrivateRoute authed={isLoggedIn()} path="/order" component={ListOrder}/>
                <PrivateRoute authed={isLoggedIn()} path="/order-detail/:id" component={DetailOrder}/>
                {/* Product */}
                {/* <PrivateRoute authed={isLoggedIn()} path="/product" exact component={ListProduct}/>
                <PrivateRoute authed={isLoggedIn()} path="/product/action" exact component={ActionProduct}/>
                <PrivateRoute authed={isLoggedIn()} path="/product/action/:id" component={ActionProduct}/>
                <PrivateRoute authed={isLoggedIn()} path="/product/:idProduct/age" exact component={ListAge}/> */}                
                {/* Info user */}
                <PrivateRoute authed={isLoggedIn()} path="/profile" exact component={Infomation}/>
                <PrivateRoute authed={isLoggedIn()} path="/profile/change-pass" exact component={ChangePass}/>
                <PrivateRoute authed={isLoggedIn()} path="/profile/user" exact component={User}/>
                <PrivateRoute authed={isLoggedIn()} path="/profile/agency" exact component={Agency}/>

            </Switch>
        </Router>
    )
}

